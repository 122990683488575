
const lazyLoadPucture = () => {
    const wrap = document.querySelectorAll(".js--lazy-wrapper");

    if(wrap.length == 0) return;

    const options = {
        threshold: [0.1]
    }

    const callback = function(entries, observer) {
        entries.forEach(entry => {
            const {target, isIntersecting, intersectionRatio} = entry;
            if(isIntersecting && !target.classList.contains('js--lazy-active')) {


                target.classList.add('js--lazy-active')
                const images = target.querySelectorAll('.js--lazy-element');
                images.forEach(elem => {
                    const parrent = elem.parentNode;
                    elem.parentNode.removeChild(elem);

                    if(!elem.getAttribute('data-lazy')) return;


                    let div = document.createElement('div');
                    div.classList.add('w-100', 'h-100')
                    let content = `<picture class="d-flex justify-content-center w-100">`
                    if(elem.getAttribute('data-lazy-xs')) {
                        content += `<source media="(max-width: 768px)" srcset="${elem.getAttribute('data-lazy-xs')}">`
                    }
                    if(elem.getAttribute('data-lazy-md')) {
                        content += `<source media="(max-width: 991px)" srcset="${elem.getAttribute('data-lazy-md')}">`
                    }
                    content += `
                            <img src="${elem.getAttribute('data-lazy')}" class="w-100" alt="plaza">
                          </picture>`;

                    div.innerHTML = content;
                    parrent.appendChild(div);
                })


            }
        });
    }

    const observer = new IntersectionObserver(callback, options);

    wrap.forEach(elem => {
        observer.observe(elem);
    })

};
export default lazyLoadPucture;