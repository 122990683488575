const findParent = (node, className) => {
    while (node) {
        if (node.classList.contains(className)) {
            return node;
        }
        else {
            node = node.parentElement;
        }
    }

    return null;
}

const find = (node) => {
    while (node) {
        if (node.hasAttribute('data-notshow-modal')) {
            return 0;
        } else {
            node = node.parentElement;
        }
    }
    return 1;
}

const containsModal = (e, overlay, btn = null, btn_delete = null, overlay_delete='', overlay_successful='', ) => {
    const margin = window.innerWidth - document.body.clientWidth;

    if(overlay.classList.contains('overlay-delete') && overlay_delete != '' && overlay_delete != '""') {
        const overlay_title = overlay.querySelector('.js--modal-delete-text span');
        if(overlay_title) overlay_title.innerHTML = overlay_delete;
    }

    if(overlay.classList.contains('overlay-successful') && overlay_successful != '' && overlay_successful != '""') {
        const overlay_title = overlay.querySelector('.js--modal-text');
        if(overlay_title) overlay_title.innerHTML = overlay_successful;
    }

    if(btn_delete) {
        btn_delete.addEventListener('click', () => {
            const file_wrap = findParent(btn, 'js--upload');
            if(file_wrap) file_wrap.classList.add('js--action-delete')
        })
    }

    document.documentElement.style.overflowY = 'hidden';
    document.documentElement.style.paddingRight = margin + 'px';

    overlay.style.zIndex = 999;
    if(btn ) btn.disabled = true;

    setTimeout(() => {
        overlay.classList.add('overlay-show')
        if(btn ) btn.disabled = false
    }, 100)
}

const showModal = (e) => {
    
    const btn = document.querySelectorAll('[data-show-modal]');

    let overlays = document.querySelectorAll(".overlay");

    const clickClose = (overlay) => {
        if (getComputedStyle(overlay.querySelector('.overlay-wrap')).marginRight != '0px') return
        overlay.classList.remove('overlay-show')
        document.documentElement.style.overflowY = 'auto';
        document.documentElement.style.paddingRight = '0';
        setTimeout(() => {
            overlay.style.zIndex = -1;
        }, 300)
    }

    overlays.forEach(overlay => {
        const closes = overlay.querySelectorAll('.js--overlay-close');

        if(closes.length != 0) {
            closes.forEach(close => {
                close.addEventListener('click', function(e) {

                    e.preventDefault()
                    clickClose(overlay)
                })
            })
        }
        overlay.addEventListener('click', function(e) {
            if (e.target != overlay) return
            clickClose(overlay)
        })
    })

    if(btn.length == 0) return;

    btn.forEach(elem => {
        let overlay = document.querySelector("." + elem.getAttribute('data-show-modal'));
        let overlay_delete = '';
        if(elem.hasAttribute('data-modal-delete')) overlay_delete = elem.getAttribute('data-modal-delete');
        let overlay_successful = 'Успешно удалено';
        if(elem.hasAttribute('data-modal-successful')) overlay_successful = elem.getAttribute('data-modal-successful');
        if(!overlay) return;

        const btn_delete = overlay.querySelector('.js--overlay-delete');
        const notshow = elem.querySelectorAll('[data-notshow-modal]');

        elem.addEventListener('click', function(e) {
            e.preventDefault();
            
        if(elem.hasAttribute('data-overlay-text')) {
            overlay.querySelector('.overlay-content').innerHTML = elem.getAttribute('data-overlay-text');
        } 
            
            let show = 1;
            show = find(e.target);
            notshow.forEach(item => {
                if (e.target == item) {
                    show = 0;
                    return;
                }
            });
            if(!show) return;
            containsModal(e, overlay, elem,  btn_delete, overlay_delete, overlay_successful)
        });
    })
}



const showNotice = (e) => {

    const btn = document.querySelectorAll('[data-show-notice]')

    if(btn.length == 0) return;

    const find = (node) => {
        while (node) {

            if (node.hasAttribute('data-notshow-notice')) {

                return 0;
            } else {
                node = node.parentElement;
            }
        }
        return 1;
    }


    btn.forEach(elem => {
        let overlay = document.querySelector("." + elem.getAttribute('data-show-notice'))
        if(!overlay) return;

        const closes = overlay.querySelectorAll('.js--notice-close')

        const notshow = elem.querySelectorAll('[data-notshow-notice]');

        elem.addEventListener('click', function(e) {
            let show = 1;
            show = find(e.target);
            notshow.forEach(item => {

                if (e.target == item) {
                    show = 0;
                    return;
                }
            });

            e.preventDefault();
            if(!show) return;

            overlay.classList.add('show')
        });
        if(closes.length != 0) {
            closes.forEach(close => {
                close.addEventListener('click', function(e) {

                    e.preventDefault()
                    clickClose()
                })
            })
        }

        // overlay.addEventListener('click', function(e) {
        //     if (e.target != overlay) return
        //     clickClose()
        // })

        const clickClose = () => {
            overlay.classList.remove('show')

        }
    })
}

window.showModals = (e, overlayClass = '', text = '') => {
    let overlay = document.querySelector(overlayClass);
    containsModal(e, overlay, null, null, '', text)
}

export {
    showModal,
    showNotice
} ;

