const showTabs = () => {
    const tabs = document.querySelectorAll('.js--tabs');

    if(tabs.length === 0) return;

    tabs.forEach(el => {
        const buttons = el.querySelectorAll('.js--tabs-btn p');
        const content = el.querySelectorAll('.js--tabs-content');

        if(buttons.length === 0 || content.length === 0) return;

        buttons.forEach((btn, item) => {
            btn.addEventListener('click', () => {
                buttons.forEach(elem => {
                    elem.classList.remove('active');
                })

                btn.classList.add('active');
                content.forEach(elem => {
                    elem.classList.add('d-none');
                })
                content[item].classList.remove('d-none');
            })
        })
    })
}

export {showTabs}