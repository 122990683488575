const showMenu = () => {
    const btn = document.querySelector('.js--open-menu');
    const menu = document.querySelector('.js--menu-mobile');
    const header = document.querySelector('header');

    if(!btn || !menu) return;


    addEventListener("resize", (event) => {
        if(window.innerWidth > 991) {
            document.documentElement.style.overflowY = 'auto';
            header.classList.remove("active");

        } else if (menu.classList.contains('active')) {
            document.documentElement.style.overflowY = 'hidden';
            header.classList.add("active");
            menu.classList.add('active');
            menu.style.zIndex = -1;
        }
    });

    btn.addEventListener('click', function(e) {
        if(menu.classList.contains('active')) {
            document.documentElement.style.overflowY = 'auto';
            menu.style.zIndex = 999;
            menu.classList.remove('active');
            header.classList.remove("active");
        } else {
            document.documentElement.style.overflowY = 'hidden';
            header.classList.add("active");
            menu.classList.add('active');
            menu.style.zIndex = -1;
        }
    });


    const menuItem = menu.querySelectorAll('a');

    if(menuItem.length == 0) return;

    menuItem.forEach(elem => {
        elem.addEventListener('click', () => {
            btn.click();
        });
    })
}

export default showMenu;