
const lazyLoadBackground = () => {
    const wrap = document.querySelectorAll(".js--leazy-background");

    if(wrap.length == 0) return;

    const options = {
        threshold: [0.1]
    }

    const callback = function(entries, observer) {
        entries.forEach(entry => {
            const {target, isIntersecting, intersectionRatio} = entry;
            if(isIntersecting && !target.classList.contains('js--lazy-active')) {
                target.classList.add('js--lazy-active')
                if(!target.getAttribute('data-lazy')) return;
                target.style.background=`url('${target.getAttribute('data-lazy')}') no-repeat center / cover`
            }
        });
    }

    const observer = new IntersectionObserver(callback, options);

    wrap.forEach(elem => {
        observer.observe(elem);
    })

};
export default lazyLoadBackground;