const showContactMap = () => {
    const wrap = document.querySelector(".js--contact-wrapper");

    if(!wrap) return;

    const options = {
        threshold: [0.4]
    }

        const callback = function(entries, observer) {
            entries.forEach(entry => {
                const {target, isIntersecting, intersectionRatio} = entry;

                if(isIntersecting && !wrap.classList.contains('ja--map-active')) {
                    wrap.classList.add('ja--map-active')
                    let script = document.createElement('script');

                    script.src = 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Ab680529601e9514eaa99eff87f0f27282afc48aa7f9fb59ee09cc0d9a2cae7fb&amp;width=500&amp;height=400&amp;lang=ru_RU&amp;scroll=true';
                    document.getElementById('contact_yamap').replaceWith(script);
                }
            });
        }
    
        const observer = new IntersectionObserver(callback, options);
        observer.observe(wrap);
}

export default showContactMap;